<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="12">
              <p>A consulta é feita com todas a condições, para o usuário ser considerado na consulta ele deve:</p>
              <ul>
                <li>Ter data de última atividade(login ou busca) no site maior que X dias</li>
                <li>Não ter ENVIADO nenhuma mensagem dentro dos X dias</li>
                <li>Não ter ABERTO nenhum dos e-mails que enviamos dentro dos X dias</li>
              </ul>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Dias sem atividade"
                label-for="days_without_login"
              >
                <b-input-group>
                  <b-form-input
                    id="days_without_login"
                    v-model="days_without_login"
                    @keyup="change"
                    @keyup.enter="getTuro"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Dias sem enviar mensagem"
                label-for="days_without_message"
              >
                <b-input-group>
                  <b-form-input
                    id="days_without_message"
                    v-model="days_without_message"
                    @keyup="change"
                    @keyup.enter="getTuro"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Dias sem abrir e-mails"
                label-for="days_without_open_email"
              >
                <b-input-group>
                  <b-form-input
                    id="days_without_open_email"
                    v-model="days_without_open_email"
                    @keyup="change"
                    @keyup.enter="getTuro"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  v-if="isSubmited && !isLoading"
                  id="btn-inactivate"
                  variant="warning"
                  @click="setTuroConfirmation()"
                >
                  Inativar usuários
                </b-button>

                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getTuro()"
                >
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => { return '#' }"
                align="end"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                :busy="isLoading"
                fixed
                :fields="result_fields"
                :items="results"
                sort-icon-left
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(user)>
                  Usuário
                </template>

                <!-- eslint-disable vue/no-v-html -->
                <template #cell(user)="row">
                  <label>Nome:</label> <span v-html="row.item.name" /><br>
                  <label>Email:</label> {{ row.item ? row.item.email : '' }}<br>
                  <label>Criado em:</label> {{ row.item ? row.item.created_at : '' | date }}<br>
                  <p v-html="row.item.presentation" />
                </template>
                <!-- eslint-enable vue/no-v-html -->

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      v-if="$can('read', 'Users')"
                      variant="flat-primary"
                      title="Abrir perfil"
                      target="_blank"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.id } }"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => { return '#' }"
                align="end"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BFormInput,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BInputGroup,
  BRow,
  BSpinner,
  BPaginationNav,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import toast from '@/mixins/toast'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BBreadcrumb,
    BButton,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BSpinner,
    BTable,
    BPaginationNav,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Efeito Turo',
          active: true,
        },
      ],
      days_without_login: 360,
      days_without_message: 100000,
      days_without_open_email: 100,
      isLoading: true,
      isSubmited: false,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      userService: null,
    }
  },
  computed: {
  },
  watch: {
    currentPage() {
      this.getTuro()
    },
  },
  created() {
    this.userService = new UserService()
    this.result_fields = [
      { key: 'id', sortable: false, thStyle: 'width: 100px' },
      { key: 'user', sortable: false },
      { key: 'actions', sortable: false, thStyle: 'width: 150px' },
    ]
  },
  methods: {
    reset() {
      this.filters = { ...this.defaultFilters }
    },
    change() {
      this.isSubmited = false
    },
    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },
    bold(str) {
      if (str == null) {
        return ''
      }
      return str.replace(/(www)/gi, '<span class="text-danger">$1</span>')
        .replace(/(http)/gi, '<span class="text-danger">$1</span>')
        .replace(/(@)/gi, '<span class="text-danger">$1</span>')
        .replace(/(.com.br)/gi, '<span class="text-danger">$1</span>')
        .replace(/([0-9]{5})/gi, '<span class="text-danger">$1</span>')
        .replace(/([0-9-]{8})/gi, '<span class="text-danger">$1</span>')
    },
    getTuro() {
      this.isSubmited = true
      this.isLoading = true

      this.userService.listTuro(this.days_without_login, this.days_without_message, this.days_without_open_email, this.currentPage)
        .then(response => {
          this.results = response.data.data.users.data
          this.pages = { ...response.data.data.users, data: null }
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setTuro() {
      this.isLoading = true

      this.userService.setTuro(this.days_without_login, this.days_without_message, this.days_without_open_email)
        .then(() => {
          this.isSubmited = false
          this.toastSuccess('Sucesso!', 'Usuários inativados com sucesso!')
        })
        .catch(() => {
          this.toastDanger('Erro!', 'Algum erro aconteceu!')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setTuroConfirmation() {
      this.$swal({
        title: 'Inativar usuários?',
        text: 'Esta ação pode ser irreversível! Pode demorar alguns minutos, dependendo da quantidade de cadastro a ser inativado...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, inativar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.setTuro()
        }
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .text-danger{
      font-weight: bold;
  }
</style>
